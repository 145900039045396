export default [
    {
        id: 1,
        name: "B M S College of Engineering",
        major: "Computer Science",
        degree: "Bachelor",
        dated: "2011 - 2015",
        cgpa: "9.12"
    },
];
